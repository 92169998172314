@import '../../../../styles/colours.scss';

.hoursColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: 2px 0;
    color: gray;
    min-height: 50px;
}

.hoursRow {
    display: flex;
    
    span {
        margin: 0 2px;
    }

    input {
        width: 25px;
        text-align: center;
        border: none;
        outline: none;
        background: none;
        font-weight: bold;
        color: gray;
    }

    input:focus {
        border: 1px solid gray;
        border-radius: 5px;
    }
}

.submitedRow {
    display: flex;
    
    span {
        margin: 0 2px;
    }

    input {
        width: 25px;
        text-align: center;
        border: none !important;
        color: gray;
        outline: none;
        background: none;
    }
}