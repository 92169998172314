@import '../../../../styles/colours.scss';

.uploadButton {
    background: transparent;
    border: 2px solid $primary-color-dark;
    margin: 0 auto;
    border-radius: 5px;
    color: $primary-color-dark;
    font-weight: bold;
    padding: 8px 13px;
    transition: background .1s, color .1s;

    &:hover {
        background: $primary-color-dark;
        color: white;
    }
}
//csstemp

.uiMealPopup {
    &Img {
        height: 145px;
        display: flex;
        flex: 1;
        margin: 0 0 0 0;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        left: auto;
        right: 0;
        top: 0;
        padding: 80px;

        .uiBtnCamera {
            background-color: #ffffff80;
            border-radius: 48px;
            min-width: 0;
            height: 44px;
            width: 44px;
        }
    }
}
