@import "../../../styles/main";
@import '../../../styles/commonStyle.module';

.uiPageTitle {
  flex-grow: 1;
  margin-bottom: 10px;

  @extend %ui-page-title;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1064px;
  margin: auto;
  text-align: center;
}

.inputContainer {
  max-width: 450px;
}

.formBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 30px;
  background: white;
  border-radius: 20px;
}

.form {
  @extend .uiForm;
  width: 80%;
}

.nav {
  display: flex;
  justify-content: flex-end;
  max-width: 950px;
  width: 100%;
  margin: 10px 0;
}