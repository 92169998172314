@import '../../../../styles/colours.scss';

.visibilityButton {
    min-width: 70px !important;
}

.modalTitle {
    border: none !important;
}

.viewButtonColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 35px;
    
    span {
        font-size: 14px;
        font-weight: bold;
        color: $primary-color-dark;
    }
}