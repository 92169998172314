.times_item {
  margin-bottom: 20px;
}
.times_item:last-child {
  margin-bottom: 0px;
}
.times_row {
  display: flex;
  align-items: center;
}
.times_label {
  width: 30px;
}
.helper_text {
  font-size: 12px;
}
