@import "../../../../styles/colours.scss";
@import "../../../../styles/main.scss";
@import "../../../../styles/commonStyle.module";

.categoryList {
  //csstemp
  padding: 35px 0;
  width: 100%;
  background: transparent;
}

.innerCategory {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.draggedItem {
  .categoryHeader {
    //background: $primary-color;
    .dragButton {
      i {
        color: $secondary-background-color;
      }
    }
  }
}

.dragContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 30px 30px;
  border-right: 1px solid lightgray;
  margin-right: 20px;
}

.dragButton {
  //margin: 15px 5px -15px;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;

  i {
    font-size: 20px;
    color: #000000;
  }

  &:hover {
    i {
      color: $primary-color-dark;
    }
  }
}

.inputTitle {
  //background: white;
  //margin-top: -3px;
  //border-radius: 5px;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: 0;
  border-radius: 8px;
  background: #edf6f4;
  padding: 0 24px 0 8px;
  margin-left: -8px;

  &:focus {
    outline: none;
  }
}

.categoryHeader {
  background: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px 10px;
  margin: -5px 0;
  cursor: pointer;
  box-shadow: 0px 0px 38px -25px $primary-color;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 0px 38px -19px $primary-color;
  }
}

.categoryHeaderIcon {
  border-radius: 10px;
  padding: 10px 15px 7px;
  background-color: $background-color-dark;
  margin: 0 20px 0 5px;

  i {
    font-size: 20px;
    color: $primary-color-dark;
  }
}

.categoryHeaderBody {
  background: transparent;
  flex: 1;

  &Content {
    box-sizing: border-box;
    padding: 23px 50px 0 25px;
    justify-content: space-between;
    display: flex;
  }
}

.categoryHeaderRow {
  //csstemp
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.categoryHeaderColumn {
  display: flex;
  flex-direction: column;
  margin: 35px auto 0 5px;
}

.deleteButton {
  display: inline-block;
  color: $primary-color-dark;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 5px 20px auto;
  font-size: 20px;
}

.imageContainer {
  //tempcss
  display: flex;
  flex-direction: column;
  margin: 20px 15px;
  border-radius: 20px;
  border: none;
  overflow: hidden;
}

.imageRow {
  //tempcss
  display: flex;
}

.headerImage {
  //tempcss
  width: 70px;
  height: 70px;
  background-color: #3d3d3d;
  padding: 5px;
}

.headerImageFiller {
  //tempcss
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $primary-color-dark;
  font-size: 23px;
  color: white;
}

.expandedMenuCard {
  max-width: 100% !important;
}

.categoryItem {
  position: relative;
}

//csstemp check above styles
.menuCard {
  box-sizing: border-box;
  margin: 20px auto 30px;
  position: relative;
  display: block;

  &Outer {
    border-radius: 20px;
    background-color: #ffffff;
    z-index: 1;
    position: relative;
    display: flex;
  }

  &Side {
    display: flex;
    width: 64px;
    min-height: 210px;
    padding: 23px 0 25px;
    box-sizing: border-box;
    //background: url("../images/ic-menu-move.svg") no-repeat center;
    justify-content: flex-end;
    flex: none;

    &Line {
      width: 1px;
      background: #d8d8d84d;
      //max-height: 335px;
    }
  }

  &::before {
    display: table;
    content: "";
    height: 70%;
    margin: 0 125px;
    width: calc(100% - 250px);
    background: #328277;
    position: absolute;
    z-index: 0;
    bottom: 0px;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
  }

  &.hovera::before,
  &:hover::before {
    display: table;
    content: "";
    height: 70%;
    margin: 0 25px;
    width: calc(100% - 50px);
    -webkit-filter: blur(18px);
    -moz-filter: blur(18px);
    -o-filter: blur(18px);
    -ms-filter: blur(18px);
    filter: blur(18px);
    opacity: 0.5;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
  }

  &Body {
    background: transparent;
    flex: 1;

    &Accordion {
      margin-top: -32px;

      &Detail {
        margin: 0 50px 0 25px;
        padding: 15px 0 0 0;
      }

      &Meals {
        margin: 0;
        padding: 0px 0 30px 0;
      }
    }

    &Content {
      box-sizing: border-box;
      padding: 23px 50px 0 25px;
      justify-content: space-between;
      display: flex;
      min-height: 185px;
    }

    .itemImg {
      height: 35px;
      vertical-align: bottom;
      margin: 0 20px 0 0;
    }

    .itemTitle {
      display: inline-block;
      line-height: 35px;
    }

    .inputTitle {
      max-width: max-content;
      display: inline-block;
      line-height: 35px;
    }

    .itemDesc {
      margin: 25px 24px 0 0;
      position: relative;
    }

    .itemDelete {
      margin: -14px -12px 0 0;
    }
    
    .itemDriveFileMove {
      margin: -14px -12px 0 0;
    }
  }

  .menuImgBlock {
    display: grid;
    grid-template-columns: 59px 59px;
    grid-template-rows: 59px 59px;
    background: #329b8317;
    border-radius: 20px;
    margin: 12px 0 0 0;
    overflow: hidden;

    &Img {
      width: 100%;
    }

    &Number {
      background-color: $color-primary;
      font-family: $font-stack;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(80deg);
}
.disabledButton {
  //opacity
  opacity: 0.5;
  display: inline-block;
  color: $primary-color-dark;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 5px 20px auto;
  font-size: 20px;
}
