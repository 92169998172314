@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';
@import '../../../../styles/variable';
@import '../../shared/styles/Modal.module';

.modalHeader {
    height: 145px;
    display: flex;
    flex: 1;
    margin: 0 0 0 0;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: space-between;
    padding: 80px;
    box-sizing: border-box;
    position: relative;

    .uiBtnCamera {
        background-color: #ffffff80;
        border-radius: 48px;
        min-width: 0;
        height: 44px;
        width: 44px;
    }

    &::before {
        display: table;
        content: '';
        background-color: #00000030;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}

h1 {
    color: #ffffff;
    font-size: 42px;
    font-weight: 600;
    z-index: 1;
}

.modalContent {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    box-sizing: border-box;
    padding: 30px 30px 42px;

    > p {
        margin: 0 0 15px;
        line-height: 20px;
    }

    .uiPrice {
        font-size: 14px;
        color: $color-text-default;
        font-weight: 500;
        margin-bottom: 30px;

        label {
            padding-left: 10px;
            color: $color-primary;
            font-weight: 700;
            font-size: 14px;
        }
    }

    .uiExtras {
        //csstemp
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
        grid-column-gap: 20px;
        margin-top: 15px;

        &Tile {
            display: inline-flex;
            width: 162px;
            height: 124px;
            border-radius: 5px;
            border: 2px solid #EDEDED;
            box-sizing: border-box;
            align-items: flex-start;
            padding: 15px 20px 25px;
            flex-direction: column;

            &.uiBtnExtra {
                border: 0;
                padding: 0;
            }

            .uiDesc {
                font-size: 7px;
                line-height: 10px;
                margin-top: 5px;
            }

            .uiImgBlock {
                width: 100%;
                text-align: right;
                padding: 6px 0 8px;
                height: 18px;

                &Img {
                    width: 18px;
                    height: 18px;
                    border-radius: 20px;
                    margin-left: -4px;
                }
            }

            .uiStroke {
                height: 2px;
                border-radius: 2px;
                width: 100%;
                position: relative;
                background-color: #8EE5AE;
                display: block;

                &::before {
                    display: table;
                    content: '';
                    width: 50%;
                    background-color: $color-primary;
                    height: 2px;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
            }
        }
    }
}

.modalAction {
    box-sizing: border-box;
    padding: 30px 0 42px;
    border-top: 2px solid #D8D8D8;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0 80px;

    button {
        margin-left: 25px;
    }
}

.breadcrumbs {
    margin: -55px 20px 0 -60px;

    .separator {
        font-size: 17px;
        font-weight: 600;
    }

    .item {
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;
        color: $primary-color-dark;
        cursor: pointer;
    }

    .currentItem {
        font-weight: 500;
        text-decoration: none;
        color: black;
        cursor: default;
    }
}

.indicatorFlag {
    display: flex;
    align-self: flex-end;
    width: 30px;
    margin: 5px;
    margin-top: -16px;
    margin-left: -8px;
    background-color: crimson;
    padding: 6px;
    border-radius: 25px;
}

.absoluteContainer {
    position: absolute;
}