.container {
  padding: 10px;
  display: flex;
  justify-content: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.DrawerClosed {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.drawer {
  margin: 40px 0px;
  background: #fff;
  border-radius: 20px;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
  .closedWrapper {
    width: 40px;
    display: flex;
    justify-content: center;
    svg {
      cursor: pointer;
      width: 20px;
      color: rgba(0, 0, 0, 0.54);
    }
    .MuiSvgIcon-root {
      width: 0.8em;
      height: 0.8em;
    }
  }
}
svg {
  cursor: pointer;
}
.drawerOpen {
  width: 300px !important;
}

.formError {
  text-align: center;
  color: red;
  width: 100%;
  overflow: hidden;
}
.row {
  &Wrapper {
    display: flex;
    align-items: center;
  }
}
.timeField {
  width: 100%;
  height: 34px !important;
  border-radius: 7px;
  padding: 10px;
  border: solid 1px green;

  div {
    min-height: 40px !important;
  }
}
.scheduleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 0 auto;

  h5 {
    margin-right: 5px;
  }
}

.validDateMessage {
  position: absolute;
  margin-top: -50px;
  margin-left: -60px;
  color: crimson;
}

.inputContainer {
  margin: 5px 0;
}

.repeatSuspendContainer {
  display: flex;
  align-items: center;

  h6 {
    margin: 0 5px;
  }
}
.ContentContainer {
  .rightArrow {
    color: rgba(0, 0, 0, 0.54);
  }
  .sameButton {
    float: right;
    margin: -37px 10px;
  }
  svg {
    margin: 20px 0px;
    width: 100%;
    justify-content: center;
  }
  .dayLabel {
    width: 100%;
    text-transform: capitalize;
    text-align: center;
    margin: 10px 0px;
    font-size: large;
  }
  .dayRow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;

    padding: 10px 0 20px 0px;
    .timeLabel {
      text-align: center;
    }
    .inputElementsStart {
      width: 160px;
    }
    .inputElementsEnd {
      width: 160px;
    }
    .inputRadioGroup {
      margin: 0px;
      button {
        justify-content: left;
        width: 115px;
        min-width: 115px;
      }
    }
    .timeLabel {
    }
    label {
      span {
        padding: 0px !important;
      }
    }
    svg {
      margin: 10px !important;
      width: unset !important;
      justify-content: unset !important;
    }
  }
}
.editTabInput {
  width: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: 0;
  border-radius: 8px;
  background: #edf6f4;
  padding: 0 14px 0 8px;
}
.editTabInput::placeholder {
  color: black;
}
.drawerActions {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.editWrapper {
  display: flex;
  padding: 0 0 0 10px;
  margin-top: -40px;
  margin-bottom: 40px;
  margin-left: 20px;
  position: relative;
  z-index: 100;
  width: 0;
  height: 0;
  .editIconWrapper {
    width: 25px;
    margin: 4px 25px 0 0px;
    display: flex;
    justify-content: center;
    opacity: 0.5;
    height: 25px;
    cursor: pointer;
    .editIcon {
      width: 16px;
    }
  }
  .editIconWrapper:hover {
    opacity: 1;
  }
}
