@import '../../../../styles/variable';
@import '../../shared/styles/Modal.module';

.modalContentContainer {
  @extend .uiModalContent;
  max-width: 860px;
  justify-content: center;
}

.fileUploadZone {
  display: block;
  height: 260px;
  max-width: 860px;
  border-radius: 20px;
  background-color: #8EE5AE1A;
  border: 2px dashed #328277;
  box-sizing: border-box;
  background-image: url("../../../../media/images/ic-sync-menu-bg.svg");
  background-repeat: no-repeat;
  background-position: center 75px;
  background-size: initial;
  position: relative;
  margin-bottom: 25px;

  &::after {
    position: absolute;
    display: table;
    content: 'Drag and drop (or click to upload) your POS export file(s) here';
    font-weight: 500;
    text-align: center;
    z-index: 1;
    color: $color-text-default;
    width: 100%;
    bottom: 70px;
    font-size: 24px;
  }
}

.fileUploadItem {
  padding: 0 0 20px;
  align-items: center;
  display: flex;
}

.hidden {
  display: none;
}

.dropZone {
  position: absolute;
  width: 100%;
  height: 100%;
}

.dropTarget {
  position: relative;
  cursor: pointer;
  z-index: 2;
  width: 100%;
  height: 100%;
  transition: background-color .3s;
}

.activeDropTarget {
  border-radius: 20px;
  box-shadow: 0px 0px 25px 2px $primary-color-dark inset;
}