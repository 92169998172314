@import '../../../../styles/colours.scss';

.staticColumn {
    display: flex;
    flex-direction: column;
    margin: 0 1px;
    text-align: center;
    width: 100%;
    color: white;
    font-weight: bold;
}

.staticHeader {
    display: flex;
    width: 100%;
    text-align: center;
    font-weight: bold;
    background: $primary-color-dark;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
}

.staticRow {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin: 2px 0;
    color: gray;
    min-height: 52px;
    font-weight: bold;
}

.menuName {
    color: gray;
    font-weight: normal;
}

.menuSearchInput {
    max-width: 200px;
}

.menuResults {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: $primary-color-dark;
    color: white;
    margin-top: 28px;
    max-width: 255px;
    width: 100%;
}

.menuOption {
    width: 100%;
    padding: 5px 0;
    cursor: pointer;
    transition: background-color .2s;

    
    &:hover {
        background-color: $primary-color;
    }
}