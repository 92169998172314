@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';
@import '../../shared/styles/Modal.module';

.publishContentContainer {
    @extend .uiModalContent;
}

.scheduleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: 0 auto;

    h5 {
        margin-right: 5px;
    }
}

.timezone {
    color: $primary-color;
}

.validDateMessage {
    position: absolute;
    margin-top: -50px;
    margin-left: -60px;
    color: crimson;
}

.inputContainer {
    margin: 5px 0;
}

.repeatSuspendContainer {
    display: flex;
    align-items: center;

    h6 {
        margin: 0 5px;
    }
}

.otherMembersTextbox {
    width: 100%;
    min-height: 200px;
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
}