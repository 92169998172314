@import '../../../../styles/variable';
@import '../../shared/styles/Modal.module';

.innerContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px auto 40px;
}

.buttons {
    margin: auto;
}

@media (min-width: 1440px) {
    .innerContentContainer {
        max-width: 1034px;
        margin: 60px 0 40px -70px;
    }
}
