@import "../../../../styles/colours.scss";
@import "../../../../styles/main.scss";
@import "../../../../styles/commonStyle.module";

.uiModal {
  &Title {
    height: 92px;
    display: flex;
    flex: 1;
    border-bottom: 2px solid #d8d8d8;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    margin: 0 50px;

    img {
      height: 35px;
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      z-index: 1;
      padding-left: 20px;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 600px;
    max-width: $dialog-width;
    box-sizing: border-box;
    padding: 20px 0px 20px;
    overflow-y: auto;

    @include desktop-hd {
      width: $dialog-width;
    }
  }

  &Action {
    box-sizing: border-box;
    padding: 20px 0;
    border-top: 2px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0 50px;

    button {
      margin-left: 25px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.uiSaveMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  img {
    height: 210px;
    margin: 60px auto 40px;
  }
}
