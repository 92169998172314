.repeat_row {
  display: flex;
  align-items: center;
  margin: 2px 0px;
}
.helper_text {
  font-size: 12px;
}
.day_label {
  font-size: 17px;
  margin-left: -6px;
}
