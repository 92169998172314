@import "styles/variable";
@import "styles/typo";
@import "styles/common";

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Baloo Chettan 2', cursive;
  scroll-behavior: smooth;
  background: $color-bg;
}

h1, h2, h3, h4, p, a, ul, li, label {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;
}

.App {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  position: relative;
}

.text-center {
  text-align: center;
}

button:focus {
  outline: none;
}

.jsoneditor-menu {
  background-color: $color-primary !important;
  border-bottom: 1px solid $color-primary !important;
}

.jsoneditor {
  margin-top: 20px !important;
  border: thin solid $color-primary !important;
}

.jsoneditor-text {
  min-height: 250px !important;
}