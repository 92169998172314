@import '../../../styles/colours.scss';
@import '../../../styles/commonStyle.module';

.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1064px;
    margin: 0 auto 20px;
}

.trashCan {
    margin: 0 5px 0 auto;
    background-color: rgb(241, 228, 231);
    color: crimson;
    padding: 6px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    text-align: start;

    i {
        float: right;
        font-size: 17px;
        margin-top: 2px;
        margin-left: 10px;
        transition: transform .2s;
    }

    &Item {
        border-radius: 10px;
        background-color: #ffffff;
        height: 80px;
        min-width: 445px;
        display: flex;
        align-items: center;
        z-index: 5;
        padding: 0 20px;
        margin: 5px;
        box-sizing: border-box;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1)  ;
        -webkit-box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1)  ;
        -moz-box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1)  ;

        &Img {
            width: 35px;
            height: 35px;
        }

        &Text {
            text-align: left;
            flex: 1;
            font-size: 16px;
            color: $color-text-default;
            margin: 0 25px 0 15px;
        }

        &Btn {
            min-width: 0;
            width: 90px
        }
    }
}

.toggledTrashCan {
    i {
        transform: rotate(90deg);
    }
}

.dropdown {
    display: none;
}

.visibleDropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
}

.dropdownOption {
    background-color: white;
    width: 240px;
    border: none;
    cursor: pointer;
    padding: 5px;

    &:hover {
        background-color: $primary-color-dark;
        color: white;
    }
}

.menuDropdownContainer {
    margin: 0 10px 0 0;
}

.pageTitle {
    margin: 0 2rem;
    color: black;
    font-size: 27px;
}

.pageTitleAuto {
    //csstemp
    margin: 0 auto 0 2rem;
}

.groupLabel {
    font-weight: 900 !important;
}

.saveButtonContainer {
    display: flex;
    flex-direction: column;
}

.readOnlyLabel {
    color: $primary-color-dark;
    font-size: 12px;
    text-align: center;
}