@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';
@import '../../../../styles/variable';
@import '../../shared/styles/Modal.module';

.storeRow {
    display: flex;
    flex-direction: row;
    margin: 0 10px;
    width: 300px;
    transition: background .2s;
    cursor: pointer;
}

.radioButton {
    width: 15px;
    height: 15px;
    border: solid 2px $primary-color-dark;
    background: none;
    border-radius: 5px;
    margin: 5px 5px 0;
    transition: background .2s;
    cursor: pointer;
}

.storeRow:hover {
    background: whitesmoke;

    .radioButton {
        background: $primary-color-dark;
    }
}

.updateButton {
    @extend %submit-button;
    margin: 5px 20px 5px auto;
}

.disabledButton {
    @extend .updateButton;
    background-color: lightgray;
    border: 1px solid lightgray;
}

.menuNameLabel {
    margin: auto 5px auto 20px !important;
    color: $primary-color-dark;
    font-weight: bold;
}

.menuNameInput {
    display: flex;
    border-radius: 5px;
    height: 30px;
    margin: auto 5px !important;
    width: 250px !important;
}

.selectedStore {
    background: whitesmoke;

    .radioButton {
        background: $primary-color-dark;

        i {
            position: relative;
            color: white;
            top: -7.2px !important;
            left: -.7px;
            font-size: 12px;
        }
    }
}

.warningMessage {
    margin: 0 auto;
    color: $primary-color-dark;
    font-weight: bold;
    position: absolute;
    bottom: 96px;
    left: 0;
    right: 0;
    text-align: center;
}

.storeCount {
    color: $primary-color;
    margin-left: 3px;
}

.headerIcon {
    @extend %header-icon;

    i {
        font-size: 25px;
    }
}

.storeDropdown {
    background: white;
    position: absolute;
    margin-left: 83px;
    width: 350px;
    max-height: 170px;
    overflow-y: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.storeRow {
    display: flex;
    flex-direction: row;
    padding: 5px 8px;
    margin: 2px;
    background: gainsboro;
    border-radius: 5px;
    width: 330px;
    transition: background .2s;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.radioButton {
    min-width: 15px;
    width: 15px;
    height: 15px;
    border: solid 2px $primary-color-dark;
    background: none;
    border-radius: 5px;
    margin: 5px 12px 3px 5px;
    transition: background .2s;
    cursor: pointer;
}

.storeRow:hover {
    background: whitesmoke;

    .radioButton {
        background: $primary-color-dark;
    }
}

.groupStores {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.groupStore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px;
    background: $secondary-background-color;
    color: black;
    cursor: pointer;
    transition: background .2s;

    &:nth-child(even) {
        background: $background-color-dark !important;
    }
}

.addStoreContainer {
    position: absolute;
    margin-left: 35px;
}

.searchedStores {
    position: absolute;
    background-color: $primary-color-dark;
    padding: 3px;
}

.storeOption {
    background-color: $primary-color-dark;
    transition: background-color .2s;
}

.storeOption:hover {
    background-color: $primary-color;
    cursor: pointer;
}

.inputContainer {
    max-width: 600px;
}

.searchInput {
    border: none;
    border-radius: 5px;
}

.inputRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 480px;
    margin-bottom: 5px;

    input {
        width: 350px !important;
        border: solid 1px lightgray;
        padding: 5px 8px;
        border-radius: 5px;
        margin-left: 15px;
    }

    ::placeholder {
        color: lightgray;
        font-weight: 100;
    }
}

.selectedStoresContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin: 0 -5px;
    padding: 10px 0;
    box-sizing: border-box;
}

.storeInGroup {
    display: flex;
    min-width: 160px;
    width: auto;
    background-color: $primary-color-dark;
    color: white;
    padding: 5px 8px 5px 15px;
    margin: 5px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    height: 30px;
    font-size: 12px;
    font-weight: 500;

    .storeDeleteButton {
        margin-left: 5px;
        cursor: pointer;
        zoom: 0.9;
    }
}

.saveMenuButtonContainer button {
    margin-left: 25px;

    &:first-of-type {
        margin-left: 25px;
    }
}
