@import "../../shared/styles/Modal.module";
@import "../../../../styles/colours.scss";

.inputContainer {
  max-width: 600px;
}

.inputLabel {
  color: $primary-color;
  margin: auto 5px;
}

.actionsContainer {
  width: 100%;
}

.actionHeading {
  display: flex;
  color: $primary-color;
  width: 100%;
  margin-left: 10px;
  justify-content: space-between;
}

.populatedIdentifier {
  font-weight: bold;
  margin-left: 3px;
}

.accordionContainer {
  margin: 5px 0 !important;
  padding: 0 5px !important;
}

.accordionSummary {
  width: 100% !important;
}

.modalContent {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  box-sizing: border-box;
}

.componentsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  max-width: 350px;
  padding: 5px;
  margin: 2px 0;
  box-shadow: none !important;
}

.noActionSavedMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding: 3px;
  margin-right: 5px;
  color: $color-primary-dark;
}

.autocompleteGroups {
  display: flex;
  flex-direction: column;
}

.autocompleteGroupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding-left: 10px;
}

.autocompleteGroupOptions {
  display: flex;
  flex-direction: column;
}

.autocompleteGroupOption {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.customScheduleBox {
  align-items: flex-start;
  border: 1px solid $color-primary-dark;
  width: 400px;
  border-radius: 4px;
  display: flex;
  height: 80px;
  padding: 2%;
  font-size: 15px;
}

.editButton {
  margin-left: auto;
  font-size: 0.8125rem;
  border: 0;
  height: 25px;
  width: 80px;
  font-size: 13px;
  font-family: "Baloo Chettan 2", cursive;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: #e0e0e0;
}
.scheduleDetails {
  width: 300px;
}
