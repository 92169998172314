.panel {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
}
.btn_close {
  margin-left: auto !important;
  justify-content: center;
}
.actionsContainer {
  margin: 20px 30px;
}
