@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';

.alertListControlContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.alertListControl {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
}

.alertListControlTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    padding: 30px;
}