@import "../../../styles/main";

.uiPageTitle {
  flex-grow: 1;
  padding: 0 !important;

  @extend %ui-page-title;
}

.menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1064px;
  margin: auto;
  text-align: center;
}

.heroImgContainer {
  @extend .menuContainer;
  position: relative;
  height: 145px;
  margin: 20px auto;
  border-radius: 20px;
  background-position: center;

  .uiBtnDelete {
    display: none;
    position: absolute;
    top: 2px;
    right: 3px;
    background-color: $primary-color !important;
    border-radius: 48px !important;
    min-width: 0 !important;
    height: 44px !important;
    width: 44px !important;
    margin-left: 80px !important;

    &:hover {
      box-shadow: 2px 4px -1px rgba(0, 0, 0, 20%), 0px 4px 5px 0px rgba(0, 0, 0, 14%), 0px 1px 10px 0px rgba(0, 0, 0, 12%) !important;
      background-color: rgb(213, 213, 213) !important;
    }
  }

  &:hover {
    .uiBtnDelete {
      display: flex;
    }

    .uiHeroImg {
      display: flex;
    }
  }
}

.inputForm {
  margin: 0 auto;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.innerTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uiHero {
  &Img {
      display: none;
      flex: 1;
      margin: 0 0 0 0;
      align-items: center;
      box-sizing: border-box;
      position: absolute;
      left: auto;
      right: 54px;
      top: 2px;

      .uiBtnCamera {
          background-color: $primary-color;
          border-radius: 48px;
          min-width: 0;
          height: 44px;
          width: 44px;
      }
  }
}

.timeTravelIconReverse {
  transform: scale(-1,1);
}