.dashboardIframe {
  overflow: hidden;
  height: 100%;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 10% 1% 1% 2%;
  }
}
