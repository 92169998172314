@import "variable";

.uiPageTitle {
  font-family: $font-stack;
  font-size: 24px;
  line-height: 24px;
  color: $color-text-default;
  font-weight: 600;
  padding: 0 20px 0 0;
}

.uiSpacer {
  height: 46px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.uiFlex {
  display: flex;
}

.uiFlexColumn {
  display: flex;
  flex-direction: column;
}

.uiFlexGrow {
  flex-grow: 1;
}

.uiFlexNone {
  flex: none;
}

.uiJustifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

.uiJustifyCenter {
  display: flex;
  justify-content: center;
}

.uiAlignCenter {
  text-align: center;
  align-items: center;
}

.uiTextRight {
  text-align: right;
}

.uiTextLeft {
  text-align: left;
}

.uiTextCenter {
  text-align: center;
}

.uiForm {
  display: grid;
  grid-template-columns: minmax(100px, max-content) 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 25px;
  padding: 10px 0;

  &Label {
    align-self: center;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
  }
}

.uiFormSelectedChips {
  display: block;
  background-color: transparent;
  margin: 0 -5px;
  padding-top: 28px;

  .uiChip {
    margin: 4px 5px;
  }

  .MuiAutocomplete-tag {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.noBorder {
  border: 0!important;
}

//override error input placeholder
.Mui-error input::placeholder {
  color: #FF0000;
  opacity: 1;
}
