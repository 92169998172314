.custom_select {
  position: relative;
  font-family: Arial;
  width: 100px;
  height: 30px;
  margin: 2px 10px;
  padding: 0 1em;
  border-radius: 0.5em;
  border: 1px solid #495162;
}

.small {
  width: 100px;
}
.large {
  width: 180px;
}
.custom_textfield {
  height: 30px;
  margin: 2px 10px;
  padding: 0 1em;
  border-radius: 0.5em;
  border: 1px solid #495162;
}
