@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';
@import '../../shared/styles/Modal.module';

.publishContentContainer {
    @extend .uiModalContent;
    
    .contentHeading {
        margin-top: 5px;
    }
}

.scheduleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: 0 auto;

    h5 {
        margin-right: 5px;
    }
}

.timezone {
    color: $primary-color;
}

.validDateMessage {
    position: absolute;
    margin-top: -50px;
    margin-left: -60px;
    color: crimson;
}