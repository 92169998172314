@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';

.closeButton:hover {
    transform: rotate(180deg);
}

.updateButton {
    @extend %submit-button;
    padding: 10px 20px;
    position: fixed;
    margin-top: 83px;
    margin-left: 10px;
    background-color: orange;
    border-color: orange;
    bottom: 0;
    top: auto;
}

.modalImageRow {
    display: flex;
    width: 100%;
    height: 220px;
    margin-top: 10px;
}

.modalImageColumn {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.modalImage {
    max-width: 60%;
    max-height: 100%;
    margin: 0 auto;
    border-radius: 20px;
}

.iconContainer {
    i {
        font-size: 190px;
        color: $primary-color-dark;
    }
}
