@import '../../../styles/variable';

.navbar {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: $navbar;
    min-height: $navbar;
    height: $navbar;
    margin: auto;
    padding: 0 20px 0;
    box-sizing: border-box;
    width: -webkit-fill-available;
    width: 100%;
    background-color: $color-bg;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;

    &.live {
        max-height: $navbar-live;
        min-height: $navbar-live;
        height: $navbar-live;
        -webkit-transition: all 400ms ease-in-out;
        transition: all 400ms ease-in-out;

        &::after {
            content: '';
            display: table;
            background-color: #DE4A49;
            width: 100%;
            height: $navbar-live;
            position: fixed;
            z-index: -1;
            left: 0;
            top: 0;
            border-radius: 0;
            box-shadow: 0px -30px 5px 23px rgba(0,0,0,1);
            -webkit-box-shadow: 0px -30px 5px 23px rgba(0,0,0,1);
            -moz-box-shadow: 0px -30px 5px 23px rgba(0,0,0,1);
        }
    }

    &Live {
        display: flex;
        position: fixed;
        left: 0;
        overflow: hidden;
        width: 0;
        height: 50px;
        opacity: 0;
        -webkit-transition: width 300ms ease;
        transition: width 300ms ease;
        align-items: center;

        img {
            margin: 0 20px 0 25px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            margin: 0 25px 0 0;
            color: #ffffff;

            &:last-of-type {
                margin: 0;
                opacity: 0.6;
                font-weight: 400;
            }
        }
    }

    &.live &Live {
        left: 0;
        width: 50%;
        opacity: 1;
        max-width: 450px;
        -webkit-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
    }

    &Inner {
        max-width: $viewport;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &User {
        height: 50px;
        min-width: 150px;
        display: flex;
        padding-left: 27px;

        &Img {
            height: 50px;
            width: 50px;
            border-radius: 50px;
            background-color: #B9B9B9;
            margin-right: 15px;
        }

        &Type {
            color: #969696;
            font-size: 11px;
            font-weight: 500;
            line-height: 2.5;
        }

        &Name {
            color: $color-text-default;
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
        }
    }

    .live &User {
        &Type {
            color: #DBDBDB;
        }
        &Name {
            color: #ffffff;
        }
    }

    &Action {
        color: $color-primary;
        display: flex;
        align-items: center;

        label {
            font-size: 16px;
            font-weight: 400;
            padding: 0 13px;
        }
    }

    &TimeTravel {
        padding: 5px 5px 5px 10px;
        background: crimson;
        border-radius: 5px;
        margin-bottom: -10px;
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .live &Action {
        color: #ffffff;
    }
    /*  @media screen and (min-width: $mobile-break) {
        max-width: $viewport;
      }*/
}
