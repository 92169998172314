@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';
@import "../../../../styles/commonStyle.module";

.outterRow {
    //csstemp
    display: inline-flex;
    flex-direction: row;
    border: none;
    margin: 4px;

    &:hover {
        .deleteItemButton, .changeCategoryButton {
            display: block;
        }
        .cardColumnOuter {
            box-shadow: 0 0 8px -3px $primary-color-dark inset;
        }
    }
}

.cardImage {
    //csstemp
    width: 150px;
    height: 150px;

    @media (max-width: 500px) {
        width: 100px;
        height: 100px;
    }
}

.cardRow {
    //csstemp
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cardColumnOuter {
    //csstemp
    border: none;
    border-radius: 15px;
    padding: 20px 10px 10px 20px;
    box-shadow: 0px 0px 30px -19px rgba(0,0,0,0.75);
    transition: box-shadow .3s;
}

.titleContainer {
    //csstemp
    padding-bottom: 10px;
    width: 100%;
}

.inputTitle {
    font-weight: bold;
    border-radius: 4px;
    width: 100%;
    padding-right: 22px;
}

.itemTitle {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color-dark;
}

.cardPrice {
    //csstemp
    color: $primary-color-light;
}

.changeCategoryModal {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    color: $color-text-default;
    background: #ffffff;
    border: 0;
    border-radius: 5px;
    position: absolute;
    z-index: 9998;
    margin: 36px 0 0 135px;
    clip-path: circle(0% at 100% 100%);
    transition: clip-path .1s ease-in-out;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1)  ;
    -webkit-box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1)  ;
    -moz-box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1)  ;
}

.nutritionalLabelModal {
    @extend .changeCategoryModal;
    margin: 36px 0 0 300px;
}

.openModalButton {
    background-color: transparent;
    border-color: transparent;
}

.changeCategoryRow {
    display: flex;
    padding: 0 20px;
    font-size: 10px;
    line-height: 20px;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
}

.visibleCategoryModal {
    clip-path: circle(100%);
}

.expandedMealItem {
    width: 400px !important;
    height: auto !important;
}

.title {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

//csstemp
.mealItem {
    border-radius: 10px;
    background: #EBF3EEB3;
    box-sizing: border-box;
    padding: 0 13px 15px 15px;
    margin: 15px 15px 0 0;
    display: inline-flex;
    flex-direction: column;
    height: 210px;

    .itemTitle {
        display: inline-block;
        line-height: 34px;
        font-weight: 600;
        font-size: 14px;
        color: $color-primary;
        padding: 2px 0 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        margin-bottom: 2px;
        z-index: auto;

        &_price {
            display: inline-block;
            line-height: 38px;
            font-weight: 600;
            font-size: 14px;
            color: $color-primary;
            padding: 2px 0 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex: none;
            bottom: 10px;
        }
    }

    &Img {
        width: 45%;
        height: 120px;
        margin: 0 auto;
    }

    &Desc {
        display: -webkit-box;
        font-size: 13px;
        color: $color-primary;
        font-weight: 400;
        margin: -4px 0 8px;
        padding-left: 15px;
        white-space: pre-wrap;
        overflow-y: hidden;
        text-overflow: ellipsis;
        height: 35px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.indicatorFlag {
    display: flex;
    position: absolute;
    margin: 5px;
    margin-top: -16px;
    margin-left: -8px;
    background-color: crimson;
    padding: 6px;
    border-radius: 25px;
}

.descPriceCol {
    width: 50%;
    height: 120px;
}

.labels {
    display: flex;
    z-index: 0;
    margin: -41px 0 0 45px;
}

.label {
    font-size: 11px;
}

.chipRoot {
    height: 25px !important;
    margin: 0 2px;
}

.chipLabel {
    margin-top: -1.25px;
}

.glutenColor {
    background-color: orange !important;
}

.spicyColor {
    background-color: #FF2F00 !important;
}

.labelIcon {
    width: 11px;
    margin: -2px 2px 0 0;
}