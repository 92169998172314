@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';

.missionControlContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.missionControl {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
}

.missionControlTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    padding: 30px;
}

.headersContainer {
    display: flex;
    padding: 0 120px 0 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}

.staticHeader {
    background-color: $primary-color-dark;
    width: 100%;
    text-align: center;
    padding: 10px;
    color: white;
}

.tableRow {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    background-color: $secondary-background-color;

    &:nth-child(even) {
        background-color: $background-color-dark;
    }
}

.tableColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 5px;
    text-align: center;

}

.changeStatusButton {
    @extend %submit-button;
    margin: 10px 5px;
    min-width: 110px !important;
}

.enabled {
    color: $primary-color;
}

.disabled {
    color: crimson;
}

.searchbarContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: solid 3px $primary-color-dark;
    background-color: $primary-color-dark;
    padding: 5px;
    width: 255px;
    margin-right: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    input {
        background: none;
        border: none;
        outline: none;
        color: white; 

        ::placeholder {
            color: white;
        }
    }

    i {
        font-size: 20px;
        color: white;
        cursor: pointer;
    }
}

.innerRow {
    display: flex;
    width: 100%;
}

.hiddenRow {
    display: none;
}

.loaderRow {
    display: flex;
    margin: 1px auto;
}

.hiddenLoaderRow {
    display: none;
}