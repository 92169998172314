@import '../../../styles/colours.scss';
@import '../../../styles/main.scss';

.outerModal {
    position: relative;
    max-width: 40%;
    margin: 4rem auto;
    width: auto;

    @media (max-width: 500px) {
        max-width: 90%;
    }
}

.innerModal {
    margin: 0 auto;
    overflow-x: hidden;
    max-height: 90vh;
    text-align: center;
    padding: 20px;
}

.modalOpened {
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity .15s linear;
    z-index: 9999;

    input, textarea {
        width: 95%;
        margin: 0 auto;
    }
}

.modalClosed {
    display: none;
    opacity: 0;
}

.buttonsRow {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
        margin: 0 5px;
    }
}
