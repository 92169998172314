@import '../../../styles/colours.scss';
@import '../../../styles/main.scss';

.uploadButton {
    @extend %submit-button;
    margin: 5px auto 5px;
}

.pendingContainer {
    margin: 0 auto;
}