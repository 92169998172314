@import "../../../styles/colours";

.topNav {
    display: flex;
    margin: 5px 0;
    justify-content: flex-end;
    align-items: center;
}

.editLink {
    color: $primary-color-dark;
    text-decoration: underline;
    cursor: pointer;
}

.removeLink {
    color: crimson;
    text-decoration: underline;
    cursor: pointer;
}

.cloneLink {
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
}