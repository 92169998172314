@import "../../../../../styles/colours.scss";

.modifierSelect {
  display: flex;
  flex-direction: column;
}

.modifierOption {
  width: fit-content;
  cursor: pointer;
}

.modifierOptionContainer {
  display: inline-flex;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #ededed;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 15px 20px 25px;
  flex-direction: column;
  background-color: white;
}

.modifierOptionStroke {
  height: 2px;
  border-radius: 2px;
  width: 100%;
  position: relative;
  background-color: #8ee5ae;
  display: block;
  margin-top: 5px;

  &::before {
    display: table;
    content: "";
    width: 50%;
    background-color: $primary-color-dark;
    height: 2px;
    top: 0;
    left: 0;
    position: absolute;
  }
}

.modifierOptionDot {
  width: 18px;
  height: 18px;
  border: solid 2px gray;
  border-radius: 50%;
  margin-top: 8.5px;
  transition: background 0.2s;
}

.modifierHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $primary-color-dark;
  color: white;
  padding: 10px 10px 5px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.actionIcon {
  margin: -9px 0 0 !important;
}

.arrowIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;

  i {
    transform: rotate(90deg);
    font-size: 28px;
    margin: 3px 3px 0 0;
    transition: transform 0.1s;
  }
}

.arrowIconRight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;

  i {
    transform: rotate(0deg);
  }
}

.inputForm {
    display: flex;
    width: fit-content !important;
}

.inputTitle {
    padding: 2px 14px 2px 2px;
    border-radius: 5px;
    background: transparent;
    border: solid 1px black !important;
    width: calc(100% + 20px) !important;
}

.mainColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
}

.leftColumn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: -15px;
}

.imageColumn {
  display: flex;
  flex-direction: column;
  width: 90px;
  align-items: flex-end;
  margin-top: 5px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 15px;
    background-image: url(../../../../../media/images/ic-card-menu.svg);
    background-position: center;
    background-size: contain;
  }
}

.modifierHeaderButtonsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.addModifierRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.addIconButton {
  width: 40px;
  height: 40px;

  span {
    color: white;
    font-weight: bold;
  }
}

.itemAutocomplete {
  position: relative;
  width: 330px;
  margin-left: auto;
  background-color: white;
  border-radius: 5px;
}

.deleteOptionIcon {
  width: 22px;
}
.priceSurchargText {
  font-weight: bold;
  color: forestgreen;
}
