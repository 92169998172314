@import "./colours";
@import "./variable";

%close-button {
    border: 1px solid $primary-color-dark;
    border-radius: 50%;
    padding-top: 3px;
    height: 50px;
    width: 50px;
    background-color: $primary-color-dark;
    color: white;
    transition: transform .5s;
}

%submit-button {
    float: right;
    border: 1px solid $primary-color-dark;
    border-radius: 5px;
    padding: 6px 12px;
    background-color: $primary-color-dark;
    color: white;
    transition: transform .5s;
    margin:10px 1rem 20px;
}

%header-icon {
    border-radius: 10px;
    padding: 10px 15px 7px;
    background-color: $background-color-dark;
    margin: 0 20px 0 25px;

    i {
        font-size: 20px;
        color: $primary-color-dark;
    }
}

%ui-page-title {
    font-family: $font-stack;
    font-size: 24px;
    line-height: 24px;
    color: $color-text-default;
    font-weight: 600;
    padding: 0 20px 0 0;
}
