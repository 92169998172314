@import '../../../styles/colours.scss';

.movedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: $primary-color-dark;
    border-radius: 50px;
    border: none;
}

.deletedIcon {
    @extend .movedIcon;
    background-color: crimson;
}

.newIcon {
    @extend .movedIcon;
    background-color: $primary-color;
}

.changedIcon {
    @extend .movedIcon;
    background-color: $primary-color-light;
}