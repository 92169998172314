.uiMain {
  position: relative;
  flex: 1;
  padding: 0 0 0 0;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &Inner {
    padding: 25px 30px 0 30px;
    background: transparent;
    //max-width: $viewport;
    overflow: visible;
    box-sizing: content-box;
    margin: auto;

    &Wr {
      background-color: transparent;
      overflow: auto;
      max-height: 100%;
      height: 100%;
    }
  }
}
