@import '../../../../../styles/colours.scss';

.paginationContainer {
    display: flex;
    margin: 20px 0;
    justify-content: center;
}

.paginationSeparator {
    display: flex;
    margin-top: 20px;
    color: white;
    justify-content: center;
    align-items: flex-end;
}

.paginationButton {
    background: $primary-color-dark;
    border: $primary-color-dark;
    width: 45px;
    padding: 5px 10px;
    outline: none;
    color: white;
    transition: background .2s;

    &:hover {
        background: $primary-color;
    }
}

.selectedButton {
    @extend .paginationButton;
    background: $primary-color;
    border: $primary-color;
}