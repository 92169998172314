@import "../../../styles/colours.scss";
@import "../../../styles/variable";

.sideNav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &Btn {
    margin: 74px auto 0 30px;
  }

  &Logo {
    margin: 18px auto 20px 32px;
  }

  &LogoPlaceholder {
    width: 40px;
    height: 30px;
  }

  &List {
    width: 100%;

    &Item {
      height: 50px;
      color: #b9b9b9;
      cursor: pointer;
      text-decoration: none;
      transition: color 200ms linear;

      &Text {
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
      }

      &.selected {
        color: $color-primary;
      }

      &.selected::after {
        display: table;
      }

      &:hover {
        text-decoration: none;
        color: $color-primary-dark;
        transition: color 120ms linear;
      }

      &::after {
        content: "";
        display: none;
        height: 100%;
        width: 5px;
        border-radius: 0 5px 5px 0;
        left: 0;
        position: absolute;
        background: $color-primary;
      }
    }
  }
}
