@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';
@import '../../shared/styles/Modal.module.scss';

.radioButton {
    min-width: 15px;
    width: 15px;
    height: 15px;
    border: solid 2px $primary-color-dark;
    background: none;
    border-radius: 5px;
    margin: 5px 12px 3px 5px;
    transition: background .2s;
    cursor: pointer;
}

.storeRow:hover {
    background: whitesmoke;

    .radioButton {
        background: $primary-color-dark;
    }
}

.saveGroupRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    //csstemp

    input {
        height: 100%;
        border: solid 1px $primary-color-dark;
        border-radius: 5px;
        width: 200px;
    }
}

.errorRow {
    display: flex;
    text-align: center;
    justify-content: center;
    min-height: 40px;
}

.saveGroupButton {
    @extend %submit-button;
    margin: 15px 10px 0 auto;
}

.selectedStore {
    background: $primary-color-dark;
    color: white;

    .radioButton {
        background: $primary-color-dark;

        i {
            position: relative;
            color: white;
            top: -7.2px !important;
            left: -.7px;
            font-size: 12px;
        }
    }
}

.groupsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.groupRow {
    margin: 5px 0;
    width: 100%;
}

.groupTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    background: $primary-color-dark;
    color: white;
    width: 100%;
    cursor: pointer;

    i {
        font-size: 25px;
    }

    .arrow {
        transition: transform .2s;
    }

    span {
        font-size: 20px;
    }

    &:hover {
        .deleteButton {
            display: inline-block;
        }
    }
}

.toggledTitle {
    .arrow {
        transform: rotate(90deg);
    }
}

.groupStores {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.groupStore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px;
    background: $secondary-background-color;
    color: black;
    cursor: pointer;
    transition: background .2s;

    &:nth-child(even) {
        background: $background-color-dark !important;
    }
}

.addStoreContainer {
    position: absolute;
    margin-left: 35px;
}

.searchedStores {
    position: absolute;
    background-color: $primary-color-dark;
    padding: 3px;
}

.storeOption {
    background-color: $primary-color-dark;
    transition: background-color .2s;
}

.storeOption:hover {
    background-color: $primary-color;
    cursor: pointer;
}

.inputContainer {
    max-width: 600px;
}

.searchInput {
    border: none;
    border-radius: 5px;
}

.itemTitle {
    font-size: 20px;
    font-weight: bold;
    margin: -3px auto 0;
    color: white;
}

.inputTitle {
    background: white;
    margin: -3px auto 0;
    border-radius: 5px;
    color: $primary-color-dark;
    font-size: 20px;
    font-weight: bold;
}

.deleteButton {
    @extend %submit-button;
    position: absolute;
    display: none;
    right: 55px;
    background: crimson;
    margin: 0 5px 0 0;
}

.inputRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 480px;
    margin-bottom: 5px;

    input {
        width: 350px !important;
        border: solid 1px lightgray;
        padding: 5px 8px;
        border-radius: 5px;
        margin-left: 15px;
    }

    ::placeholder {
        color: lightgray;
        font-weight: 100;
    }
}

.selectedStoresContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    padding: 20px 45px;
    box-sizing: border-box;
}

.storeInGroup {
    display: flex;
    min-width: 160px;
    width: auto;
    background-color: $primary-color-dark;
    color: white;
    padding: 5px 8px 5px 15px;
    margin: 5px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    height: 30px;
    font-size: 12px;
    font-weight: 500;

    .storeDeleteButton {
        margin-left: 5px;
        cursor: pointer;
        zoom: 0.9;
    }
}
