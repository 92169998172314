$font-stack: 'Baloo Chettan 2', cursive;
$color-text-default: #3D3D3D;
$color-bg: #F7F9FC;
$color-primary-dark: #336B6B;
$color-primary: #328277;
$color-primary-light: #5BC3AB;

$navbar: 70px;
$navbar-live: 70px;

$desktop-xl-width: 1440px;
$viewport: 1064px;
$dialog-width: 860px;

:export {
  colorPrimary: $color-primary
}

@mixin desktop-hd {
  @media (min-width: #{$desktop-xl-width}) {
    @content;
  }
}
