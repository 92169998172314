
// csstemp , this file shpuold be delete

.ui-page-title {
  font-family: $font-stack;
  font-size: 24px;
  line-height: 24px;
  color: $color-text-default;
  font-weight: 600;
  padding: 0 20px 0 0;
}

.ui-spacer {
  height: 46px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.ui-flex {
  display: flex;
}

.ui-flex-grow {
  flex-grow: 1;
}

.ui-flex-none {
  flex: none;
}

.ui-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.ui-justify-center {
  display: flex;
  justify-content: center;
}

.ui-align-center {
  text-align: center;
  align-items: center;
}

.ui-text-right {
  text-align: right;
}

.ui-text-left {
  text-align: left;
}

.ui-form {
  display: grid;
  grid-template-columns: minmax(100px, max-content) 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 25px;
  padding: 10px 0;

  &__label {
    align-self: center;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
  }
}

.ui-form-selected-chips {
  display: block;
  background-color: transparent;
  margin: 0 -5px;
  padding-top: 28px;

  .ui-chip {
    margin: 4px 5px;
  }

  .MuiAutocomplete-tag {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.no-border {
  border: 0!important;
}

//override error input placeholder
.Mui-error input::placeholder {
  color: #FF0000;
  opacity: 1;
}

//override autocomplete (Theme override not working with ts)
.MuiAutocomplete-input {
  padding: 0!important;
}
