.menuTravelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px 0;
    min-width: 500px;
}

.menuTravelBtn {
    margin-left: 5px !important;
}