@import '../../../../styles/colours.scss';

.topRow {
    margin: 5px 0 0 10px;
    display: flex;
    padding: 0 15px 0 15px;
    justify-content: flex-end;
    width: 100%;
}

.dropdownHeader {
    display: flex;
    color: white;
    background: $primary-color-dark;
    padding: 10px 10px 8px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 153px;
    cursor: pointer;

    i {
        margin-bottom: -6px;
        margin-left: 10px;
        font-size: 23px;
    }
}

.dropdownOptions {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 153px;
    top: 128px;
    right: 15px;
    background: white;
    color: $primary-color-dark;
    border: 2px solid $primary-color-dark;
}

.dropdownOption {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;
}

.dropdownOption:hover {
    background: $primary-color-dark;
    color: white;
}

.arrowIcon {
    float: right;

    i {
        transform: rotate(90deg);
        transition: transform .1s;
    }
}

.arrowIconRight {
    i {
        transform: rotate(0deg);
    }
}

.searchbarContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: solid 3px $primary-color-dark;
    background-color: $primary-color-dark;
    padding: 5px;
    margin-right: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    input {
        background: none;
        border: none;
        outline: none;
        color: white; 

        ::placeholder {
            color: white;
        }
    }

    i {
        font-size: 20px;
        color: white;
        cursor: pointer;
    }
}

::placeholder {
    color: white;
}