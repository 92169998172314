@import '../../../../styles/colours.scss';
@import '../../../../styles/main.scss';
@import "../../../../styles/commonStyle.module";
@import "../../../MenuManagement/CategoryList/styles/CategoryList.module.scss";

.storesContainer {
    padding: 15px 5px;
}

.storeRow {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 500px;
    transition: background .2s;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
}

.storesInGroup {
    display: flex;
    flex-wrap: wrap;
}

.radioButton {
    width: 15px;
    height: 15px;
    border: solid 2px $primary-color-dark;
    background: none;
    border-radius: 5px;
    margin: 5px 5px 0;
    transition: background .2s;
    cursor: pointer;
}

.storeRow:hover {
    background: whitesmoke;

    .radioButton {
        background: $primary-color-dark;
    }
}

.saveGroupRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.errorRow {
    display: flex;
    text-align: center;
    justify-content: center;
}

.saveGroupButton {
    @extend %submit-button;
    margin: 0 10px;
}

.selectedStore {
    background: whitesmoke;

    .radioButton {
        background: $primary-color-dark;

        i {
            position: relative;
            color: white;
            top: -7.2px !important;
            left: -.7px;
            font-size: 12px;
        }
    }
}

.groupRow {
    //csstemp
    margin: 0;
    width: 100%;
}

.groupHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    margin: 20px 0;
    background: white;
    color: $primary-color-dark;
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0px 0px 38px -25px $primary-color;
    transition: box-shadow .2s;

    &:hover {
        box-shadow: 0px 0px 38px -19px $primary-color;
    }

    i {
        font-size: 25px;
    }

    span {
        font-size: 20px;
    }
}

.groupStores {
    display: flex;
    max-width: 80%;
    flex-wrap: wrap;
    margin: 0 auto;
}

.groupStore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    width: 450px;
    padding: 8px 10px;
    background: red;
    border-radius: 25px;
    color: white;
    cursor: pointer;
}

.addStoreContainer {
    position: absolute;
    width: 300px;
    right: 200px;
    z-index: 100000;

    ::placeholder {
        color: lightgray;
    }
}

.searchedStores {
    position: absolute;
    background-color: $primary-color-dark;
    padding: 3px;
    width: 250px;
    z-index: 99;
}

.storeOption {
    background-color: $primary-color-dark;
    color: white;
    font-weight: normal;
    font-size: 14px;
    transition: background-color .2s;
    padding: 2px;
}

.storeOption:hover {
    background-color: $primary-color;
    cursor: pointer;
}

.searchInput {
    margin-left: 30px;
    margin-top: 10px;
    width: 250px;
}

.addIcon {
    margin-left: 14px;
    display: inline-block;
    i {
        font-size: 30px;
    }
}
//csstemp all above
.menuCardBodyContent {
    padding: 23px 50px 0 50px;
}

.menuCardBodyAccordion {
    margin-left: 25px;

    &DetailTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        h3 {
            flex-grow: 1;
            text-align: left;
        }
    }
}

.storeItem {
    border-radius: 10px;
    background: #EBF3EEB3;
    box-sizing: border-box;
    padding: 0 13px 15px 15px;
    margin: 15px 15px 0 0;
    display: inline-flex;
    flex-direction: column;
    width: 230px;

    &Top {
        display: flex;
        flex-grow: 1;
    }

    &Title {
        display: inline-block;
        line-height: 18px;
        font-weight: 600;
        font-size: 14px;
        color: $color-primary;
        padding: 12px 7px 0 0;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
    }
}
