@import '../../../styles/colours.scss';

.toastifyWrapper {
    & > * {
        background: whitesmoke !important;
        padding: 25px !important;
        color: $primary-color-dark !important;
        border-radius: 10px !important;
        font-weight: bold !important;
        box-shadow: 0px 25px 60px -5px rgba(0,0,0,0.75) !important;
    }
}

.errorWrapper {
    & > * {
        color: crimson !important;
    }
}

.toastifyBody {
    background: whitesmoke;
}

.toastifyProgressBar {
    background: $primary-color !important;
    height: 7px !important;
}

.errorProgressBar {
    background-color: crimson !important;
}